import React from "react"
import { Nav, Container, Row, Col, NavItem, NavLink } from "reactstrap"

const Footer: React.FC = () => {
  return (
    <footer className="footer has-cards">
      <Container>
        <Row className="align-items-center justify-content-md-between">
          <Col md="6">
            <div className="copyright">© {new Date().getFullYear()} Evo-IS Limited.</div>
          </Col>
          <Col md="6">
            <Nav className="nav-footer justify-content-end">
              <NavItem>
                <NavLink href="/privacy">Privacy Policy</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/terms">Terms & Conditions</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
