import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"

export interface LogoData {
  logo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export default (): LogoData => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "brand/logo_transparent.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return data
}
