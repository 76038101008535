import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Headroom from "headroom.js"
import useLogoData from "../hooks/useSiteLogo"
// JavaScript plugin that hides or shows a component based on your scroll
import {
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from "reactstrap"

type HeaderProps = {
  siteTitle?: string
}

const Header: React.FC<HeaderProps> = ({ siteTitle = "" }) => {
  const [collapseClasses, setCollapseClasses] = useState("")
  const { logo } = useLogoData()

  useEffect(() => {
    //todo useref, but not sure why it wasnt working...
    const headroom = new Headroom(document.getElementById("navbar-main"))
    headroom.init()
  })

  return (
    <header className="header-global">
      <Navbar
        className="navbar-main navbar-transparent navbar-light headroom"
        expand="lg"
        id="navbar-main"
        data-testid="header"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img alt={siteTitle} src={logo.childImageSharp.fluid.srcWebp} />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar_global">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            toggler="#navbar_global"
            navbar
            className={collapseClasses}
            onExiting={() => setCollapseClasses("collapsing-out")}
            onExited={() => setCollapseClasses("")}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img alt="..." src={logo.childImageSharp.fluid.srcWebp} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar_global">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  href="https://www.instagram.com/evo.is"
                  id="tooltip356693867"
                  target="_blank"
                >
                  <i className="fa fa-instagram" />
                  <span className="nav-link-inner--text d-lg-none ml-2">Instagram</span>
                </NavLink>
                <UncontrolledTooltip delay={0} target="tooltip356693867">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
