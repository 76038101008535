/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import Footer from "./footer"

import "../assets/vendor/nucleo/css/nucleo.css"
import "../assets/vendor/font-awesome/css/font-awesome.min.css"
import "../assets/scss/argon-design-system-react.scss?v1.1.0"
import { useSnackbar } from "notistack"
import { Button } from "reactstrap"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useLogoData from "../hooks/useSiteLogo"
import CookieConsent, { Cookies } from "react-cookie-consent"

type LayoutProps = {
  children: React.ReactNode
  location?: Location
}

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  const isServer = typeof window === "undefined"
  const mainRef = useRef<HTMLElement>(null)
  const { logo } = useLogoData()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0
    if (mainRef.current) mainRef.current.scrollTop = 0
  }, [])

  !isServer &&
    window.addEventListener("sworkerUpdate", () => {
      enqueueSnackbar("This application has been updated. Reload to display the latest version", {
        variant: "info",
        persist: true,
        anchorOrigin: { vertical: "top", horizontal: "right" },
        action: function RefreshButton() {
          /* istanbul ignore next */
          return (
            <Button
              aria-label="Refresh page"
              onClick={() => {
                window.location.reload()
              }}
              color="inherit"
            >
              Refresh
            </Button>
          )
        },
      })
    })

  return (
    <>
      <GatsbySeo
        openGraph={{
          images: [
            {
              url: `${data.site.siteMetadata.siteUrl}${logo.childImageSharp.fluid.srcWebp}`,
              alt: "Logo",
            },
          ],
          url: isServer ? undefined : `${data.site.siteMetadata.siteUrl}${location?.pathname}`,
        }}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main ref={mainRef}>{children}</main>
      <Footer />
      <CookieConsent
        overlay
        onAccept={() => {
          Cookies.set("gdpr-analytics-enabled", true, { expires: 365 })
        }}
      >
        <p>
          This website stores cookies on your computer. These cookies are used to collect
          information about how you interact with this website and allow us to remember you. We use
          this information in order to improve and customize your browsing experience and for
          analytics and metrics about our visitors on this website.
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </CookieConsent>
    </>
  )
}

export default Layout
